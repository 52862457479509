import {mapActions, mapGetters} from "vuex";

export default {
    data(){
     return{
         number:'',
         phoneButtons:[
             {
                 number:1,
                 span:'',
                 status: true,
             },
             {
                 number:2,
                 span:'ABC',
                 status: true,
             },
             {
                 number:3,
                 span:'DEF',
                 status: true,
             },
             {
                 number:4,
                 span:'GHI',
                 status: true,
             },
             {
                 number:5,
                 span:'JKL',
                 status: true,
             },
             {
                 number:6,
                 span:'MNO',
                 status: true,
             },
             {
                 number:7,
                 span:'PQRS',
                 status: true,
             },
             {
                 number:8,
                 span:'TUV',
                 status: true,
             },
             {
                 number:9,
                 span:'WXYZ',
                 status: true,
             },
             {
                 number:'*',
                 span:'',
                 status: false,
             },
             {
                 number:0,
                 span:'+',
                 status: true,
             },
             {
                 number:'<',
                 span:'',
                 status: true,
             },
         ]
     }
 },
    computed:{
        ...mapGetters({
            profile:'profile/profile',
        })
    },
    created() {
        if(!this.profile){
            this.getProfile();
        }
    },
    methods:{
        sendCall(){
            if(this.number.length === 10){
                const payload = {
                    'order_id':null,
                    "number_from":this.profile.internal_phone_binotel,
                    "number_to":this.number,
                    "type_call": "internal-number-to-external-number"
                }
                this.createCall(payload).then(res=>{
                    if(res.status){
                        this.$toasted.success('Дзвінок здійснено успішно!',{
                            duration: 3000
                        })
                    }else{
                        this.$toasted.error(JSON.stringify(res.result.message),{
                            duration: 3000
                        })
                    }
                });
            }else{
                this.$toasted.error('Не правильний формат телефону',{
                    duration: 3000
                })
            }
        },
        ...mapActions({
            createCall: "binotel/createCall",
            getProfile:'profile/getProfile',
        })
    }
}
